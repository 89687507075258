import React, {useEffect} from "react";
import {useNavigate} from 'react-router-dom';
import {getSignature, checkCustomerCache, getGroupExternalContactList} from "../../request/api/auth/auth_api";
import {checkKhResponse, getKhErrorMsg} from "../../util/util";
import CircularProgress from '@mui/material/CircularProgress';
import {jsApiList} from "../../util/constant";
import Toast from "../../component/Toast";
import {Box} from "@mui/material";

//微信授权文件相关
const wx = window.wx;

/**
 * 页面主组件
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function User(props) {
    const navigate = useNavigate();
    //loading
    let [loading, setLoading] = React.useState(true);
    //toast
    let [toast, setToast] = React.useState({
        type: "success",
        msg: null,
        open: false,
        duration: 2000
    });

    /**
     * 消息提示
     */
    const toastMsg = (type, msg, open = true, duration = 2000) => {
        setToast({type: type, msg: msg, open: open, duration: duration});
    }

    /**
     * 关闭消息
     */
    const closeToast = () => {
        setToast({type: toast.type, open: false});
    }

    /**
     * 单独聊天，获取用户信息
     */
    const getSingleExternalContact = () => {
        wx.invoke('getCurExternalContact', {}, function (res) {
            if (res.err_msg === 'getCurExternalContact:ok') {
                //外部联系人id
                let externalUserId = res.userId;
                if (externalUserId == null || externalUserId === "") {
                    toastMsg("error", "微信授权失败，请联系管理员")
                } else {
                    //通过该外部联系人id判断是否需要进入setting页面，也就是判断该联系人id在内存中是否有缓存
                    checkCustomerCache({externalContactId: externalUserId}).then(res => {
                        let success = checkKhResponse(res);
                        if (success) {
                            let haveCache = res["dataMap"]["haveCustomerCache"];
                            if (haveCache) {
                                //有缓存，进入data页面
                                navigate("/data", {state: {contactId: externalUserId}});
                            } else {
                                //没缓存，进入setting页面
                                navigate("/setting", {state: {contactId: externalUserId}})
                            }
                        }
                    });
                }
            } else {
                setLoading(false);
                toastMsg("error", "微信授权失败，请联系管理员");
            }
        })
    }

    /**
     * 群聊天，获取群用户信息
     */
    const getGroupExternalContact = () => {
        //获取当前群聊id
        wx.invoke('getCurExternalChat', {}, function (res) {
            if (res.err_msg === "getCurExternalChat:ok") {
                let chatId = res.chatId;
                //通过chatid获取该群的客户列表
                getGroupExternalContactList({chatId: chatId}).then(data => {
                    let success = checkKhResponse(data);
                    if (success) {
                        let idList = data["dataMap"]["idList"];
                        if (idList.length > 1) {
                            toastMsg("error", "客户群结构错误");
                        }else {
                            //跳转到setting
                            let externalUserId = idList[0];
                            //通过该外部联系人id判断是否需要进入setting页面，也就是判断该联系人id在内存中是否有缓存
                            checkCustomerCache({externalContactId: externalUserId}).then(ress => {
                                let success = checkKhResponse(ress);
                                if (success) {
                                    let haveCache = ress["dataMap"]["haveCustomerCache"];
                                    if (haveCache) {
                                        //有缓存，进入data页面
                                        navigate("/data", {state: {contactId: externalUserId}});
                                    } else {
                                        //没缓存，进入setting页面
                                        navigate("/setting", {state: {contactId: externalUserId}})
                                    }
                                }
                            });
                        }
                    }else {
                        toastMsg("error", getKhErrorMsg(data))
                    }
                })
            } else {
                toastMsg("error", "微信授权失败，请联系管理员")
            }
        });
    }

    useEffect(() => {
        //第二步: 获取微信授权，必须在登录成功后进行，否则无法获取签名相关数据
        let pageUrl = window.location.href.split("#")[0];
        getSignature({pageUrl: pageUrl}).then(signRes => {
            //解析signRes
            let signSuccess = checkKhResponse(signRes);
            if (signSuccess) {
                let signatureInfo = signRes["dataMap"];
                //解析
                let nonceStr = signatureInfo["nonceStr"];
                let timeStamp = signatureInfo["timestamp"];
                let signature = signatureInfo["signature"];
                let corpId = signatureInfo["corpId"];
                let agentId = signatureInfo["agentId"];

                //通过上面三个参数和pageUrl获取微信授权
                wx.agentConfig({
                    corpid: corpId,
                    agentid: agentId,
                    timestamp: timeStamp,
                    nonceStr: nonceStr,
                    signature: signature,
                    jsApiList: jsApiList,
                    //授权成功回调
                    success: function (res) {
                        wx.invoke('getContext', {}, function (res) {
                            if (res.err_msg === "getContext:ok") {
                                let entry = res.entry;
                                if (entry === 'single_chat_tools') {
                                    //toastMsg("success", "单独聊天");
                                    //单独聊天，获取当前客户的客户内容
                                    getSingleExternalContact();
                                } else if (entry === 'group_chat_tools') {
                                    //toastMsg("success", "群聊天");
                                    //群聊天
                                    getGroupExternalContact();
                                } else {
                                    toastMsg("error", "应用非法，请按照规定方法使用")
                                }
                            } else {
                                toastMsg("error", "微信授权失败，请联系管理员")
                            }
                        });
                    },
                    //授权失败回调
                    fail: function (res) {
                        setLoading(false);
                        toastMsg("error", "微信授权失败，请联系管理员");
                    }
                })
            } else {
                setLoading(false);
                toastMsg("error", signRes["dataMap"]["msg"] === null ? "系统错误" : signRes["dataMap"]["msg"]);
            }
        });
    }, [])

    return (
        <Box sx={{
            display: 'flex',
            width: '100vw',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        }}>
            <Toast open={toast.open} duration={toast.duration} type={toast.type} msg={toast.msg} close={closeToast}/>
            {loading ? <CircularProgress/> : ""}
        </Box>
    )
}

export default User;