import axios from "axios";

const localStorage = window.localStorage;

axios.defaults.timeout = 100000;
axios.defaults.baseURL = "http://sv.kuaifawu.com/wechatapi";
//axios.defaults.baseURL = "http://localhost:5418/wechatapi";

/**
 * http request 拦截器
 */
axios.interceptors.request.use(
    (config) => {
        config.data = JSON.stringify(config.data);
        config.headers = {
            "Content-Type": "application/json",
            "sessionId": localStorage.getItem("sessionId")
        };
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

/**
 * http response 拦截器
 */
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        //如果错误码为403则为未登录需要跳转页面
        //error.code
        switch (error.response.status) {
            case 403:
                //清空storage内的sessionid
                localStorage.removeItem("sessionId");
                //跳转到oauth页面，重新授权
                window.location.href = "/";
                break;
            case 406:
                //说明微信相关api出错，跳转到手动登录页面
                window.location.href = "/usernameOauth";
            default:
                break;
        }
    }
);

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data) {
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(
            (response) => {
                //关闭进度条
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

//统一接口处理，返回数据
export default function (url, params) {
    let _data = "";
    return new Promise((resolve, reject) => {
        post(url, params)
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            });
    });
}
