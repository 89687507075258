import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import {otherRouter, pageRouter} from './routerList';

const allRouter = [...pageRouter, ...otherRouter];

export default function Index() {
    const renderRouter = (router) =>
        router.map((item, index) =>
            item.path ? (
                <Route
                    key={index}
                    path={item.path}
                    element={item.element}
                    {...(item.props = {})}
                >
                    {item.children && renderRouter(item.children)}
                </Route>
            ) : (
                <Route
                    key={index}
                    path={item.from}
                    element={<Navigate to={item.to} replace/>}
                />
            )
        );

    return (
        <React.Fragment>
            <Routes>{renderRouter(allRouter)}</Routes>
        </React.Fragment>
    );
}