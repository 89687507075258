import React, {useEffect} from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {checkKhResponse} from "../../util/util";
import {getCustomerMobileList, getCustomerListByMobile, saveSelectCustomer} from "../../request/api/auth/auth_api";
import Toast from "../../component/Toast";
import TextField from '@mui/material/TextField';

const localStorage = window.localStorage;

/**
 * 步骤条
 */
const steps = [
    {
        label: '输入客户手机号',
        description: '',
    },
    {
        label: '选择客户',
        description: '',
    }
];

function Setting() {
    const navigate = useNavigate();
    const location = useLocation();
    //contactId
    let [contactId, setContactId] = React.useState(0);
    //步骤
    let [activeStep, setActiveStep] = React.useState(0);
    //已选择手机号
    let [selectedMobile, setSelectedMobile] = React.useState("");
    //已选择的客户
    let [selectedCustomer, setSelectedCustomer] = React.useState("");
    //手机号列表
    let [mobiles, setMobiles] = React.useState([]);
    //客户列表
    let [customers, setCustomers] = React.useState([]);
    //toast
    let [toast, setToast] = React.useState({
        type: "success",
        msg: null,
        open: false,
        duration: 2000
    });

    /**
     * 消息提示
     */
    const toastMsg = (type, msg, open = true, duration = 2000) => {
        setToast({type: type, msg: msg, open: open, duration: duration});
    }

    /**
     * 关闭消息
     */
    const closeToast = () => {
        setToast({type: toast.type, open: false});
    }

    /**
     * 下一步
     */
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        //这步为选择手机号步骤，选择完了之后会到下一步，需要在此步获取客户列表
        if (activeStep === 0) {
            if (selectedMobile == null || selectedMobile === "") {
                //如果没选手机号则退回到第一步
                toastMsg("warning", "请选择手机号");
                handleReset();
            } else {
                getCustomerListByMobile({contactMobile: selectedMobile}).then(res => {
                    let success = checkKhResponse(res);
                    if (success) {
                        let customersList = res["dataMap"]["customers"];
                        if (customersList === null || customersList.length === 0) {
                            toastMsg("error", "系统错误");
                        } else {
                            setCustomers(customersList);
                        }
                    } else {
                        toastMsg("error", res["dataMap"]["msg"] === null ? "系统错误" : res["dataMap"]["msg"]);
                    }
                });
            }
        }

        //此步为确认客户步骤，选择好了后需要将此客户传入后端
        if (activeStep === 1) {
            if (selectedCustomer == null || selectedCustomer === "" || selectedCustomer === 0) {
                toastMsg("warning", "请选择客户");
            } else {
                //调用后端将customerId存入
                saveSelectCustomer({customerId: selectedCustomer, externalContactId: contactId}).then(res => {
                    let success = checkKhResponse(res);
                    if (success) {
                        //成功则跳转页面至account
                        navigate("/data", {state: {contactId: contactId}});
                    } else {
                        toastMsg("error", res["dataMap"]["msg"] === null ? "系统错误" : res["dataMap"]["msg"]);
                    }
                })
            }
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        //因为只有两步，所以只有最后一步才能调用此方法
        //清空列表
        setCustomers([]);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    /**
     * 选择手机号
     */
    function handleMobileSelect(event, mobile) {
        setSelectedMobile(mobile);
    }

    /**
     * 选择客户
     */
    function handleCustomerSelect(event, customerId) {
        setSelectedCustomer(customerId);
    }

    useEffect(() => {
        //获取跳转至此页面的拼接参数
        let state = location.state;
        if (state !== null && state !== '') {
            let contactId = state.contactId;
            if (contactId == null || contactId === '') {
                toastMsg("error", "系统错误，错误码,010，请联系管理员");
            } else {
                setContactId(contactId);
                localStorage.setItem("contactId", contactId);
                //获取手机号列表
                getCustomerMobileList({externalContactId: contactId}).then(res => {
                    let success = checkKhResponse(res);
                    if (success) {
                        //判断手机号列表是否为空， 为空则报错
                        let mobileList = res["dataMap"]["mobiles"];
                        if (mobileList === null || mobileList.length === 0) {
                            toastMsg("warning", "请在该客户备注中填写手机号", true, 2000);
                        } else {
                            setSelectedMobile(mobileList[0]["mobile"])
                            setMobiles(mobileList);
                        }
                    } else {
                        //查询失败
                        toastMsg("error", res["dataMap"]["msg"] === null ? "系统错误" : res["dataMap"]["msg"], true, 2000);
                    }
                });
            }
        } else {
            toastMsg("error", "系统错误", true, 2000);
        }

    }, [])

    return (
        <>
            {/* toast 默认 */}
            <Toast open={toast.open} duration={toast.duration} type={toast.type} msg={toast.msg} close={closeToast}/>

            {/* 手机号客户选择 */}
            <Box>
                <Stepper activeStep={activeStep} orientation="vertical"
                         sx={{paddingLeft: "10px", paddingRight: "10px"}}>
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel
                                optional={
                                    index === 1 ? (
                                        <Typography variant="caption">最后一步</Typography>
                                    ) : null
                                }
                            >
                                {step.label}
                            </StepLabel>
                            <StepContent>
                                <Box>
                                    {/* 第一步 手机号列表 */}
                                    {index === 0 && (
                                        <>
                                            {/*<List component="nav" aria-label="main mailbox folders">
                                                {
                                                    mobiles.map(
                                                        (mobile) =>
                                                            <ListItemButton
                                                                selected={selectedMobile === mobile["mobile"]}
                                                                onClick={(event) => handleMobileSelect(event, mobile["mobile"])}
                                                            >
                                                                <ListItemText primary={mobile["mobile"] + "    " + mobile["name"]}/>
                                                            </ListItemButton>
                                                    )
                                                }
                                            </List>*/}
                                            <TextField value={selectedMobile} id="outlined-basic" label="手机号" variant="outlined" onChange={event => setSelectedMobile(event.target.value)}/>
                                        </>
                                    )}

                                    {/* 第二步 客户列表 */}
                                    {index === 1 && (
                                        <>
                                            <List component="nav" aria-label="main mailbox folders">
                                                {
                                                    customers.map(
                                                        (customer) =>
                                                            <ListItemButton
                                                                selected={selectedCustomer === customer.id}
                                                                onClick={(event) => handleCustomerSelect(event, customer.id)}
                                                            >
                                                                <ListItemText primary={customer.name}/>
                                                            </ListItemButton>
                                                    )
                                                }
                                            </List>
                                        </>
                                    )}

                                </Box>
                                <Box sx={{mb: 2}}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                            sx={{mt: 1, mr: 1}}
                                        >
                                            {"确认"}
                                        </Button>
                                        <Button
                                            disabled={index === 0}
                                            onClick={handleBack}
                                            sx={{mt: 1, mr: 1}}
                                        >
                                            上一步
                                        </Button>
                                    </div>
                                </Box>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length && (
                    <Paper square elevation={0} sx={{p: 3}}>
                        <Typography>All steps completed - you&apos;re finished</Typography>
                        <Button onClick={handleReset} sx={{mt: 1, mr: 1}}>
                            Reset
                        </Button>
                    </Paper>
                )}
            </Box>
        </>
    );
}

export default Setting;