import React from "react";
import {getAgentId} from "../../request/api/auth/auth_api";
import {useLocation} from "react-router-dom";
import {checkKhResponse, getKhErrorMsg} from "../../util/util";
import Toast from "../../component/Toast";
import {Box, CircularProgress} from "@mui/material";

export default function PrivateOAuth() {
    let location = useLocation();
    //loading
    let [loading, setLoading] = React.useState(true);
    //toast
    let [toast, setToast] = React.useState({
        type: "success",
        msg: null,
        open: false,
        duration: 2000
    });

    /**
     * 消息提示
     */
    const toastMsg = (type, msg, open = true, duration = 2000) => {
        setToast({type: type, msg: msg, open: open, duration: duration});
    }

    /**
     * 关闭消息
     */
    const closeToast = () => {
        setToast({type: toast.type, open: false});
    }

    React.useEffect(() => {
        let state = location.state;
        if (state !== null && state !== '') {
            let corpId = state.corpId;
            if (corpId !== null && corpId !== '') {
                //调用后端获取agentId
                getAgentId({corpId: corpId}).then(result => {
                    let success = checkKhResponse(result);
                    if (success) {
                        let agentId = result["dataMap"]["agentId"];
                        let windowUrl = "http://sv.kuaifawu.com/#/privateLogin";
                        let encodeWindowUrl = encodeURIComponent(windowUrl);
                        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid="+ corpId +"&redirect_uri="+ encodeWindowUrl +"&response_type=code&scope=snsapi_privateinfo&agentid="+ agentId +"&state="+ corpId +"#wechat_redirect"
                    } else {
                        setLoading(false);
                        toastMsg("error", getKhErrorMsg(result))
                    }
                });
            }else {
                setLoading(false);
                toastMsg("error", "系统错误，请联系管理员");
            }
        }else {
            setLoading(false);
            toastMsg("error", "系统错误，请联系管理员");
        }
    }, []);

    return (
        <Box sx={{
            display: 'flex',
            width: '100vw',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        }}>
            <Toast open={toast.open} duration={toast.duration} type={toast.type} msg={toast.msg} close={closeToast}/>
            {loading ? <CircularProgress/> : ""}
        </Box>
    )
}