/**
 * 获取url中的参数
 */
export function getVariable(location, variable) {
    let paramsUrl = location.split("?");
    let paramsUrlLength = paramsUrl.length;
    if (paramsUrlLength === 1) {
        return null;
    }
    let params = paramsUrl[1].split("&");
    let paramsLength = params.length;

    for (let i = 0; i < paramsLength; i++) {
        let paramPair = params[i].split("=");
        if (paramPair[0] === variable) {
            return paramPair[1];
        }
    }
    return null;
}

/**
 * 检查请求快合结果是否合法
 */
export function checkKhResponse(response) {
    let success = true;
    if (response === null || response.success === null || response.success === 0) {
        success = false;
    }
    return success;
}

/**
 * 获取请求错误信息
 */
export function getKhErrorMsg(response) {
    return response["dataMap"]["msg"] === null ? "系统错误" : response["dataMap"]["msg"];
}