import * as React from 'react';
import {useState} from "react";
import {Box} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {zhCN} from "date-fns/locale";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import CardActions from "@mui/material/CardActions";
import LoadingButton from "@mui/lab/LoadingButton";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import {getSalary} from "../../request/api/data/data_api";
import moment from "moment";
import {checkKhResponse} from "../../util/util";
import Toast from "../../component/Toast";

/**
 * 工资表
 */
function Salary(props) {
    const {contactId, wx} = props;
    //起始和结束账期
    let [period, setPeriod] = useState(new Date());

    //按钮及查询结果的加载框
    let [loading, setLoading] = useState(false);
    let [toast, setToast] = React.useState({
        type: "success",
        msg: null,
        open: false,
        duration: 2000
    });

    //salary查询结果
    let [salary, setSalary] = useState("");

    //发送按钮禁用
    let [sendDisabled, setSendDisabled] = useState(false);

    /**
     * 消息提示
     */
    const toastMsg = (type, msg, open = true, duration = 2000) => {
        setToast({type: type, msg: msg, open: open, duration: duration});
    }

    /**
     * 关闭消息
     */
    const closeToast = () => {
        setToast({type: toast.type, open: false});
    }

    /**
     * 发送消息/文件到客户
     * type: 类型
     * id： type为file的时候id为mediaId， type为text的时候id为文本
     */
    const sendChatMsg = async (type, id) => {
        let p = new Promise(function(resolve, reject){
            id = id.toString();
            switch (type) {
                case "file":
                    wx.invoke('sendChatMessage', {
                        msgtype: "file", //消息类型，必填
                        enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
                        file: {
                            mediaid: id, //文件的素材id
                        },
                    }, function (res) {
                        resolve(res.err_msg === 'sendChatMessage:ok');
                    })
                    break;
                case "text":
                    wx.invoke('sendChatMessage', {
                        msgtype: "text", //消息类型，必填
                        enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
                        text: {
                            content: id, //文件的素材id
                        },
                    }, function (res) {
                        resolve(res.err_msg === 'sendChatMessage:ok');
                    })
                    break;
                default:
                    resolve(false);
            }
        });
        return p;
    }

    //查询工资表
    const querySalary = () => {
        //params.getPeriod(), params.getExternalContactId(), userInfo.getCorpId()
        getSalary({period: moment(period).format("YYYYMM"), externalContactId: contactId}).then(res => {
            let success = checkKhResponse(res);
            if (success) {
                let mediaId = res["dataMap"]["mediaId"];
                if (mediaId === null || mediaId === '') {
                    toastMsg("warning", "工资表为空");
                }else {
                    setSalary(mediaId);
                }
                setLoading(false);
            }else {
                setLoading(false);
                toastMsg("error", res["dataMap"]["msg"] === null ? "系统错误" : res["dataMap"]["msg"]);
            }
        })
    }

    //发送工资表
    const sendSalary = () => {
        setSendDisabled(true);
        sendChatMsg("file", salary).then(res => {
            if (res === true) {
                toastMsg("success", "发送成功");
                setSendDisabled(false);
            }else {
                toastMsg("error", "发送失败，请联系管理员");
                setSendDisabled(false);
            }
        })
    }

    return (
        <Box sx={{width: "100%"}}>

            {/* toast 默认 */}
            <Toast open={toast.open} duration={toast.duration} type={toast.type} msg={toast.msg} close={closeToast}/>

            <Card sx={{m: 2}}>
                <CardContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={zhCN}>
                        <DatePicker
                            cancelText={"关闭"}
                            okText={"确认"}
                            inputFormat={"yyyy 年 MM 月"}
                            views={['year', 'month']}
                            label="选择账期"
                            minDate={new Date('2012-03')}
                            maxDate={new Date()}
                            value={period}
                            onChange={(newValue) => {
                                setPeriod(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} helperText={null}/>}
                        />
                    </LocalizationProvider>

                    <Box sx={{height: "20px"}}/>

                    <Box sx={{display: "flex", flexDirection: "row"}}>
                        <Typography sx={{lineHeight: "30px"}} variant={"body2"}>{(salary !== null && salary !== "") ? "已查询到工资表，该文件不支持预览" : ""}</Typography>
                        <CircularProgress size={18} sx={{lineHeight: "30px", display: loading ? "block" : "none"}}/>
                    </Box>

                </CardContent>

                <CardActions>

                    <LoadingButton
                        onClick={querySalary}
                        endIcon={<SearchIcon/>}
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                    >
                        查询
                    </LoadingButton>

                    <Box sx={{width: "10px"}}/>

                    <LoadingButton
                        disabled={sendDisabled}
                        onClick={sendSalary}
                        endIcon={<SendIcon/>}
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                    >
                        发送
                    </LoadingButton>
                </CardActions>
            </Card>
        </Box>
    )
}

export default Salary;