import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import {useEffect} from "react";
import {useNavigate, useLocation} from 'react-router-dom';
import {
    getCustomerInfo,
    getSignature,
    unboundSelectedCustomer,
} from "../../request/api/auth/auth_api";
import {checkKhResponse} from "../../util/util";
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import {jsApiList} from "../../util/constant";
import Salary from "./Salary";
import Profit from "./Profit";
import Financial from "./Financial";
import File from "./File";
import Invoice from "./Invoice";
import {getMiniProgramLogo} from "../../request/api/data/data_api";
import IosShareIcon from '@mui/icons-material/IosShare';
import Toast from "../../component/Toast";

//微信授权文件相关
const wx = window.wx;

//localStorage
const localStorage = window.localStorage;

/**
 * 子菜单，项目1，账套数据项
 */
function BookTabPanel(props) {
    const {contactId} = props;
    //子项目的下标
    let [value, setValue] = React.useState(0);
    /**
     * 子菜单的更新
     */
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    return (
        <Box>
            <Tabs
                indicatorColor="primary"
                textColor="inherit"
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons={false}
                aria-label="scrollable prevent tabs example"
            >
                <Tab label="利润额" {...a11yProps(0)} />
                <Tab label="工资表" {...a11yProps(1)} />
                <Tab label="财务报表" {...a11yProps(2)} />
                <Tab label="文件列表" {...a11yProps(3)} />
                <Tab label="开票信息" {...a11yProps(4)} />
            </Tabs>

            {/* 利润额 */}
            {value === 0 && (
                <Profit contactId={contactId} wx={wx}/>
            )}

            {/* 工资表 */}
            {value === 1 && (
                <Salary contactId={contactId} wx={wx}/>
            )}

            {/* 税务报表 */}
            {value === 2 && (
                <Financial contactId={contactId} wx={wx}/>
            )}

            {/* 文件列表 */}
            {value === 3 && (
                <File contactId={contactId} wx={wx}/>
            )}

            {/* 开票信息 */}
            {value === 4 && (
                <Invoice contactId={contactId} wx={wx}/>
            )}
        </Box>
    )
}

/**
 * 主菜单
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function RootTabPanel(props) {
    const {children, value, index, contactId, ...other} = props;

    return (
        <Box
            sx={{width: "100%"}}
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {index === 0 && (
                /* 账套数据子项 */
                <BookTabPanel contactId={contactId}/>
            )}
        </Box>
    );
}

RootTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function Data() {
    let theme = useTheme();
    let location = useLocation();
    let navigate = useNavigate();
    let [value, setValue] = React.useState(0);
    //toast
    let [toast, setToast] = React.useState({
        type: "success",
        msg: null,
        open: false,
        duration: 2000
    });
    //客户名
    let [customerName, setCustomerName] = React.useState("");
    //客户对应的contactId
    let [contactId, setContactId] = React.useState("");
    //重选客户按钮的loading
    let [loading, setLoading] = React.useState(false);
    //发送小程序的按钮的loading
    let [miniLoading, setMiniLoading] = React.useState(false);
    /**
     * 主菜单栏变化
     */
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    /**
     * 消息提示
     */
    const toastMsg = (type, msg, open = true, duration = 2000) => {
        setToast({type: type, msg: msg, open: open, duration: duration});
    }

    /**
     * 关闭消息
     */
    const closeToast = () => {
        setToast({type: toast.type, open: false});
    }

    /**
     * 发送小程序
     */
    const sendMiniprogram = () => {
        setMiniLoading(true);

        getMiniProgramLogo().then(res => {
            let success = checkKhResponse(res);
            if (success) {
                let logoUrl = res["dataMap"]["imgUrl"];
                if (logoUrl === null || logoUrl === "") {
                    toastMsg("error", "系统错误，请联系管理员");
                    setMiniLoading(true);
                } else {
                    wx.invoke('sendChatMessage', {
                        msgtype: "miniprogram", //消息类型，必填
                        enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
                        miniprogram:
                            {
                                appid: "wxba6a5b02be400300",//小程序的appid，企业已关联的任一个小程序
                                title: "快合票税助手", //小程序消息的title
                                imgUrl: logoUrl,//小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
                                page: "pages/login/login.html", //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
                            },
                    }, function (res) {
                        if (res.err_msg === 'sendChatMessage:ok') {
                            toastMsg("success", "分享成功");
                            setMiniLoading(false);
                        } else {
                            toastMsg("error", "分享失败，请将小程序授权至工作台");
                            setMiniLoading(false);
                        }
                    })
                }
            } else {
                toastMsg("error", res["dataMap"]["msg"] === null ? "系统错误" : res["dataMap"]["msg"]);
                setMiniLoading(true);
            }
        })
    }

    /**
     * 重新选择客户
     */
    const reSelectCustomer = () => {
        setLoading(true);
        //需要调用后端接口，将contactId和customerId解绑
        unboundSelectedCustomer({externalContactId: contactId}).then(res => {
            let success = checkKhResponse(res);
            if (success) {
                toastMsg("success", "解绑成功");
                //跳转到setting页面重新选择
                navigate("/setting", {state: {contactId: contactId}});
            } else {
                toastMsg("error", res["dataMap"]["msg"] === null ? "系统错误" : res["dataMap"]["msg"]);
            }
        })
    }

    useEffect(() => {
        let state = location.state;
        if (state !== null && state !== '') {
            let externalContact = state.contactId;
            if (externalContact == null || externalContact === '') {
                //如果没有则说明错误，或为直接进入该页面，强制跳转至oauth
                navigate("/");
            } else {
                //把contactId存到state里
                setContactId(externalContact);
                localStorage.setItem("contactId", externalContact);
                //根据contactId查询后端该contactId指向的customerInfo
                getCustomerInfo({externalContactId: externalContact}).then(res => {
                    let success = checkKhResponse(res);
                    if (success) {
                        //将结果存入
                        let customerInfo = res["dataMap"]["customerInfo"];
                        setCustomerName(customerInfo.name);
                        let pageUrl = window.location.href.split("#")[0];
                        getSignature({pageUrl: pageUrl}).then(signRes => {
                            //解析signRes
                            let signSuccess = checkKhResponse(signRes);
                            if (signSuccess) {
                                let signatureInfo = signRes["dataMap"];
                                //解析
                                let nonceStr = signatureInfo["nonceStr"];
                                let timeStamp = signatureInfo["timestamp"];
                                let signature = signatureInfo["signature"];
                                let corpId = signatureInfo["corpId"];
                                let agentId = signatureInfo["agentId"];

                                //通过上面三个参数和pageUrl获取微信授权
                                wx.agentConfig({
                                    corpid: corpId,
                                    agentid: agentId,
                                    timestamp: timeStamp,
                                    nonceStr: nonceStr,
                                    signature: signature,
                                    jsApiList: jsApiList,
                                    //授权成功回调
                                    success: function (res) {

                                    },
                                    //授权失败回调
                                    fail: function (res) {
                                        toastMsg("error", "微信授权失败，请联系管理员");
                                    }
                                })
                            } else {
                                toastMsg("error", signRes["dataMap"]["msg"] === null ? "系统错误" : signRes["dataMap"]["msg"]);
                            }
                        });
                    } else {
                        toastMsg("error", res["dataMap"]["msg"] === null ? "系统错误" : res["dataMap"]["msg"]);
                    }
                });
            }
        } else {
            //如果没有则说明错误，或为直接进入该页面，强制跳转至oauth
            navigate("/");
        }
    }, []);

    return (
        <Box>
            <Toast open={toast.open} duration={toast.duration} type={toast.type} msg={toast.msg} close={closeToast}/>

            {/* 客户名和重授权的appbar */}
            <Box>
                {/* 客户名，及重授权按钮 */}
                <AppBar position="static" style={{backgroundColor: "#1199c4"}}>
                    <Toolbar>
                        <Typography variant="subtitle1" component="div" fontSize={"14px"} sx={{flexGrow: 1}}>
                            {customerName}
                        </Typography>

                        <LoadingButton
                            onClick={reSelectCustomer}
                            endIcon={<RefreshIcon/>}
                            loading={loading}
                            loadingPosition="end"
                            color={"inherit"}
                        >
                            <Typography fontSize={"12px"}>
                                切换客户
                            </Typography>
                        </LoadingButton>

                        <LoadingButton
                            onClick={sendMiniprogram}
                            endIcon={<IosShareIcon/>}
                            loading={miniLoading}
                            loadingPosition="end"
                            color={"inherit"}
                        >
                            <Typography fontSize={"12px"}>
                                分享开票小程序
                            </Typography>
                        </LoadingButton>
                    </Toolbar>
                </AppBar>

                {/* 主菜单栏 */}
                <AppBar position="static" style={{backgroundColor: "#1199c4"}}>
                    <Tabs
                        indicatorColor="secondary"
                        textColor="inherit"
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons={false}
                        aria-label="scrollable prevent tabs example"
                    >
                        <Tab label="账套" {...a11yProps(0)} />
                    </Tabs>
                </AppBar>
            </Box>

            <Box>
                <RootTabPanel value={value} index={0} dir={theme.direction} contactId={contactId}/>
            </Box>
        </Box>
    );
}

export default Data;