import React, {useEffect, useReducer} from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {checkKhResponse, getVariable} from "../util/util";
import {checkCustomerCache, getSignature, login} from "../request/api/auth/auth_api";
import {jsApiList} from "../util/constant";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Toast = ({open = false, duration = 3000, msg = "", type = "success", close}) => {
    return (
        <Snackbar open={open} autoHideDuration={duration} onClose={close} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
            <Alert onClose={close} severity={type} sx={{width: '100%'}}>
                {msg}
            </Alert>
        </Snackbar>
    )
}

export default Toast;