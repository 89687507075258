import './App.css';
import React from 'react';
import {HashRouter} from 'react-router-dom';
import Index from './router/index';

function App() {
    return (
        <div>
            <HashRouter>
                {<Index/>}
            </HashRouter>
        </div>
    );
}

export default App;
