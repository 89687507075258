import * as React from "react";
import {useEffect, useState} from "react";
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import {getFileList} from "../../request/api/data/data_api";
import {checkKhResponse} from "../../util/util";
import {uploadFile} from "../../request/api/data/data_api";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import DownloadIcon from '@mui/icons-material/Download';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import FolderIcon from '@mui/icons-material/Folder';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import Toast from "../../component/Toast";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';

function TablePaginationActions(props) {
    const theme = useTheme();
    const {count, page, rowsPerPage, onPageChange} = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{flexShrink: 0, ml: 2.5}}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon/> : <FirstPageIcon/>}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon/> : <LastPageIcon/>}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

/**
 * 文件列表
 */
function File(props) {
    const {contactId, wx} = props;
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [totalElements, setTotalElements] = useState(0);
    let [fileName, setFileName] = useState("");
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * 10 - totalElements) : 0;

    let [fileList, setFileList] = useState([]);

    //提示
    let [toast, setToast] = useState({
        type: "success",
        msg: null,
        open: false,
        duration: 2000
    });

    /**
     * 消息提示
     */
    const toastMsg = (type, msg, open = true, duration = 2000) => {
        setToast({type: type, msg: msg, open: open, duration: duration});
    }

    /**
     * 关闭消息
     */
    const closeToast = () => {
        setToast({type: toast.type, open: false});
    }

    function queryFileList(fkPage) {
        console.log(fileName)
        getFileList({externalContactId: contactId, page: fkPage, pageSize: 10, fileName: fileName}).then(res => {
            let success = checkKhResponse(res);
            if (success) {
                setFileList([]);
                let fileListArr = res["dataMap"]["fileList"];
                let totalElements = res["dataMap"]["totalElements"];
                if (fileListArr !== null && fileListArr.length > 0) {
                    setFileList(fileListArr);
                }
                setTotalElements(totalElements);
            } else {
                toastMsg("error", res["dataMap"]["msg"] === null ? "系统错误" : res["dataMap"]["msg"]);
            }
        });
    }

    const updateSending = (index, flag) => {
        fileList[index].loading = flag;
        setFileList(fileList);
    }

    //页面变化
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        //根据newPage查询后端文件结果
        queryFileList(newPage);
    };

    //params.getKhMediaId(), userInfo.getCorpId(), params.getExternalContactId()
    const sendFile = (fileId, index) => {
        updateSending(index, true);
        if (fileId === null || fileId === "") {
            toastMsg("error", "文件错误");
            updateSending(index, false);
        } else {
            uploadFile({khMediaId: fileId, externalContactId: contactId}).then(res => {
                let success = checkKhResponse(res);
                if (success) {
                    let mediaId = res["dataMap"]["mediaId"];
                    if (mediaId === null || mediaId === "") {
                        toastMsg("error", "发送失败");
                        updateSending(index, false);
                    } else {
                        sendChatMsg("file", mediaId).then(res => {
                            if (res === true) {
                                toastMsg("success", "发送成功");
                                updateSending(index, false);
                            } else {
                                toastMsg("error", "发送失败，请联系管理员");
                                updateSending(index, false);
                            }
                        })
                    }
                } else {
                    toastMsg("error", res["dataMap"]["msg"] === null ? "系统错误" : res["dataMap"]["msg"]);
                    updateSending(index, false);
                }
            });
        }
    }

    /**
     * 发送消息/文件到客户
     * type: 类型
     * id： type为file的时候id为mediaId， type为text的时候id为文本
     */
    const sendChatMsg = async (type, id) => {
        let p = new Promise(function (resolve, reject) {
            id = id.toString();
            switch (type) {
                case "file":
                    wx.invoke('sendChatMessage', {
                        msgtype: "file", //消息类型，必填
                        enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
                        file: {
                            mediaid: id, //文件的素材id
                        },
                    }, function (res) {
                        resolve(res.err_msg === 'sendChatMessage:ok');
                    })
                    break;
                case "text":
                    wx.invoke('sendChatMessage', {
                        msgtype: "text", //消息类型，必填
                        enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
                        text: {
                            content: id, //文件的素材id
                        },
                    }, function (res) {
                        resolve(res.err_msg === 'sendChatMessage:ok');
                    })
                    break;
                default:
                    resolve(false);
            }
        });
        return p;
    }

    useEffect(() => {
        queryFileList(page);
    }, []);


    return (
        <Box sx={{width: "100%"}}>
            <Toast open={toast.open} duration={toast.duration} type={toast.type} msg={toast.msg} close={closeToast}/>

            <Card sx={{m: 2}}>
                <CardContent>

                    <FormControl sx={{ width: '100%', mb: 2 }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">文件名</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={'text'}
                            value={fileName}
                            onChange={event => setFileName(event.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => queryFileList(0)}
                                        edge="end"
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            placeholder={'请输入文件名'}
                            label="Password"
                            onKeyUp={event => {
                                if (event.code === "Enter" || event.code === "NumpadEnter") {
                                    queryFileList(0);
                                }
                            }}
                        />
                    </FormControl>
                    <TableContainer component={Paper}>
                        <Table sx={{maxWidth: '100%'}} aria-label="custom pagination table">
                            <TableBody>
                                {fileList.map((row, index) => (
                                    <ListItem
                                        secondaryAction={
                                            <IconButton edge="end" aria-label="delete" onClick={() => sendFile(row.id, index)}>
                                                {!row.loading ? <DownloadIcon/> : <CircularProgress size={22}/>}
                                            </IconButton>
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                <FolderIcon/>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={row.fileName}
                                        />
                                    </ListItem>
                                ))}
                                {emptyRows > 0 && (
                                    <TableRow style={{height: 53 * emptyRows}}>
                                        <TableCell colSpan={6}/>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[10]}
                                        colSpan={3}
                                        count={totalElements}
                                        rowsPerPage={10}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Box>
    )
}


export default File;