import * as React from "react";
import {useEffect, useState} from "react";
import {getProfit} from "../../request/api/data/data_api";
import moment from "moment";
import {checkKhResponse} from "../../util/util";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {zhCN} from "date-fns/locale";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import CardActions from "@mui/material/CardActions";
import LoadingButton from "@mui/lab/LoadingButton";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import {getBookPeriod} from "../../request/api/data/data_api";
import Toast from "../../component/Toast";

//localStorage
const localStorage = window.localStorage;

/**
 * 子菜单。项目1，账套数据项，利润额
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Profit(props) {
    const {contactId, wx} = props;
    //起始和结束账期
    let [beginPeriod, setBeginPeriod] = useState(new Date());
    let [endPeriod, setEndPeriod] = useState(new Date());

    //按钮及查询结果的加载框
    let [loading, setLoading] = useState(false);
    let [toast, setToast] = React.useState({
        type: "success",
        msg: null,
        open: false,
        duration: 2000
    });

    //profit查询结果
    let [profit, setProfit] = useState("");

    //发送按钮禁用
    let [sendDisabled, setSendDisabled] = useState(false);

    //当前账期
    let [currentPeriod, setCurrentPeriod] = useState("");
    //结束账期
    let [closePeriod, setClosePeriod] = useState("");

    //查询账套账期
    const queryBookPeriod = () => {

        let cid = localStorage.getItem("contactId");

        //查询后端获取当前和结束账期
        getBookPeriod({externalContactId: cid}).then(res => {
            let success = checkKhResponse(res);
            if (success) {
                let currentPeriod = res["dataMap"]["currentPeriod"];
                let closePeriod = res["dataMap"]["closePeriod"];

                setCurrentPeriod(currentPeriod);
                setClosePeriod(closePeriod);

                if (closePeriod !== null) {
                    setBeginPeriod(moment(closePeriod, "YYYYMM").toDate());
                    setEndPeriod(moment(closePeriod, "YYYYMM").toDate());
                }
            }else {
                toastMsg("error", res["dataMap"]["msg"] === null ? "系统错误" : res["dataMap"]["msg"]);
            }
        })
    }

    //查询利润额
    const queryProfit = () => {
        setLoading(true);
        //参数校验
        if (beginPeriod === null || endPeriod === null || (typeof(endPeriod) === "undefined")) {
            toastMsg("warning", "请选择起始或结束账期");
            setLoading(false);
        }else {
            getProfit({externalContactId: contactId, incomePeriodList: [moment(beginPeriod).format("YYYYMM"), moment(endPeriod).format("YYYYMM")]}).then(res => {
                let success = checkKhResponse(res);
                if (success) {
                    let profit = res["dataMap"]["profit"];
                    if (profit === null) {
                        profit = 0;
                    }
                    setProfit(profit);
                    setLoading(false);
                }else {
                    setLoading(false);
                    toastMsg("error", res["dataMap"]["msg"] === null ? "系统错误" : res["dataMap"]["msg"]);
                }
            })
        }
    }

    /**
     * 消息提示
     */
    const toastMsg = (type, msg, open = true, duration = 2000) => {
        setToast({type: type, msg: msg, open: open, duration: duration});
    }

    /**
     * 关闭消息
     */
    const closeToast = () => {
        setToast({type: toast.type, open: false});
    }

    //发送利润额
    const sendProfit = () => {
        setSendDisabled(true);

        if (profit === '' || profit === null) {
            toastMsg("warning", "请先查询");
        }else {
            sendChatMsg("text", moment(beginPeriod).format("YYYY年MM月").toString() + " 至 " + moment(endPeriod).format("YYYY年MM月").toString() + "利润额: " + profit + "元").then(res => {
                if (res === true) {
                    toastMsg("success", "发送成功");
                    setSendDisabled(false);
                }else {
                    toastMsg("error", "发送失败，请联系管理员");
                    setSendDisabled(false);
                }
            })
        }
    }

    /**
     * 发送消息/文件到客户
     * type: 类型
     * id： type为file的时候id为mediaId， type为text的时候id为文本
     */
    const sendChatMsg = async (type, id) => {
        let p = new Promise(function(resolve, reject){
            id = id.toString();
            switch (type) {
                case "file":
                    wx.invoke('sendChatMessage', {
                        msgtype: "file", //消息类型，必填
                        enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
                        file: {
                            mediaid: id, //文件的素材id
                        },
                    }, function (res) {
                        resolve(res.err_msg === 'sendChatMessage:ok');
                    })
                    break;
                case "text":
                    wx.invoke('sendChatMessage', {
                        msgtype: "text", //消息类型，必填
                        enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
                        text: {
                            content: id, //文件的素材id
                        },
                    }, function (res) {
                        resolve(res.err_msg === 'sendChatMessage:ok');
                    })
                    break;
                default:
                    resolve(false);
            }
        });
        return p;
    }

    useEffect(() => {
        queryBookPeriod();
    }, []);

    return (
        <Box sx={{width: "100%"}}>

            {/* toast 默认 */}
            <Toast open={toast.open} duration={toast.duration} type={toast.type} msg={toast.msg} close={closeToast}/>

            <Card sx={{ m: 2 }}>
                <CardContent>
                    <Typography variant="subtitle2" gutterBottom component="div">
                        账套当前账期为 ： {moment(currentPeriod, "YYYYMM").format("YYYY 年 MM 月")} <br/>
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom component="div">
                        账套结束账期为 ： {moment(closePeriod, "YYYYMM").format("YYYY 年 MM 月")}
                    </Typography>
                    <Box sx={{height: "15px"}} />
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={zhCN}>
                        <DatePicker
                            cancelText={"关闭"}
                            okText={"确认"}
                            inputFormat={"yyyy 年 MM 月"}
                            views={['year', 'month']}
                            label="选择起始账期"
                            minDate={new Date('2012-03')}
                            maxDate={new Date()}
                            value={beginPeriod}
                            onChange={(newValue) => {
                                setBeginPeriod(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} helperText={null} />}
                        />
                    </LocalizationProvider>

                    <Box sx={{height: "15px"}}/>

                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={zhCN}>
                        <DatePicker
                            cancelText={"关闭"}
                            okText={"确认"}
                            inputFormat={"yyyy 年 MM 月"}
                            views={['year', 'month']}
                            label="选择结束账期"
                            minDate={new Date('2012-03')}
                            maxDate={new Date()}
                            value={endPeriod}
                            onChange={(newValue) => {
                                setEndPeriod(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} helperText={null} />}
                        />
                    </LocalizationProvider>

                    <Box sx={{height: "20px"}}/>

                    <Box sx={{display: "flex", flexDirection: "row"}}>
                        <Typography sx={{lineHeight: "30px"}} variant={"body2"}>利润额：{profit}</Typography>
                        <CircularProgress size={18} sx={{lineHeight: "30px", display: loading ? "block" : "none"}}/>
                    </Box>

                </CardContent>

                <CardActions>

                    <LoadingButton
                        onClick={queryProfit}
                        endIcon={<SearchIcon />}
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                    >
                        查询
                    </LoadingButton>

                    <Box sx={{width: "10px"}}/>

                    <LoadingButton
                        disabled={sendDisabled}
                        onClick={sendProfit}
                        endIcon={<SendIcon />}
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                    >
                        发送
                    </LoadingButton>
                </CardActions>
            </Card>
        </Box>
    );
}

export default Profit;