import React, {useEffect, useState} from "react";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {Grid, Stack} from "@mui/material";
import Toast from "../../component/Toast";
import {loginByUsername} from "../../request/api/auth/auth_api";
import {checkKhResponse, getVariable} from "../../util/util";
import {useNavigate} from "react-router-dom";
import md5 from 'js-md5';
import LoadingButton from '@mui/lab/LoadingButton';
React.Component.prototype.$md5 = md5;


const localStorage = window.localStorage;


function LoginByUsername() {
    //navigate
    let navigate = useNavigate();

    //账号密码
    const [values, setValues] = React.useState({
        code: '',
        username: '',
        password: '',
        showPassword: false,
    });

    //loading
    let [loading, setLoading] = React.useState(false);

    //toast
    let [toast, setToast] = React.useState({
        type: "success",
        msg: null,
        open: false,
        duration: 2000
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        //先判断有没有sessionId
        let sessionId = localStorage.getItem("sessionId");
        if (sessionId === null || sessionId === '') {
            //获取code
            let code = getVariable(window.location.href, "code");
            if (code === null || code === "") {
                toastMsg("error", "系统错误，请联系服务提供商");
                //直接跳转到新页面
                navigate("/error");
            } else {
                setValues({
                    code: code
                });
            }
        } else {
            //跳转到user页面
            navigate("/user");
        }
    }, []);

    /**
     * 账号密码登录
     */
    const login = () => {
        setLoading(true);
        if (values.username === null || values.username === '') {
            toastMsg("error", "用户名不能为空");
            return false;
        }
        if (values.password === null || values.password === '') {
            toastMsg("error", "密码不能为空");
            return false;
        }
        //登录
        loginByUsername({username: values.username, password: md5(values.password), loginType: "ACCOUNT", code: values.code}).then(res => {
            let success = checkKhResponse(res);
            if (success) {
                let sessionId = res["dataMap"]["sessionId"];
                //存入sessionId
                localStorage.setItem("sessionId", sessionId);
                //跳转到user
                navigate("/user");
            } else {
                setLoading(false);
                toastMsg("error", res["dataMap"]["msg"] === null ? "系统错误" : res["dataMap"]["msg"]);
            }
        });
    }

    /**
     * 微信授权登录
     */
    const loginByWeChat = () => {
        //跳转到OAuth
        navigate("/oauth");
    }

    /**
     * 消息提示
     */
    const toastMsg = (type, msg, open = true, duration = 2000) => {
        setToast({type: type, msg: msg, open: open, duration: duration});
    }

    /**
     * 关闭消息
     */
    const closeToast = () => {
        setToast({type: toast.type, open: false});
    }

    return (
        <Box sx={{height: "100vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
            <Box sx={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <FormControl sx={{ m: 1, width: "65%" }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-username">账号</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-username"
                        type={"text"}
                        value={values.username}
                        onChange={handleChange("username")}
                        label="Username"
                    />
                </FormControl>
            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <FormControl sx={{ m: 1, width: "65%" }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">密码</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChange('password')}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="password"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                </FormControl>
            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "7px"}}>
                <LoadingButton
                    sx={{width: "65%"}}
                    variant="contained"
                    loading={loading}
                    onClick={login}
                >
                    登录
                </LoadingButton>
            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "7px"}}>
                <Button variant="contained" sx={{width: "65%"}} onClick={loginByWeChat}>已付费授权登录</Button>
            </Box>

            {/* toast 默认 */}
            <Toast open={toast.open} duration={toast.duration} type={toast.type} msg={toast.msg} close={closeToast}/>
        </Box>
    )
}

export default LoginByUsername;