import React, {useEffect} from "react";
import {getVariable, checkKhResponse, getKhErrorMsg} from "../../util/util";
import {Box, CircularProgress} from "@mui/material";
import {loginByWeChat} from "../../request/api/auth/auth_api";
import Toast from "../../component/Toast";
import {useNavigate} from 'react-router-dom';
const localStorage = window.localStorage;

function LoginByWeChat() {
    //navigate
    let navigate = useNavigate();
    //loading
    let [loading, setLoading] = React.useState(true);
    //toast
    let [toast, setToast] = React.useState({
        type: "success",
        msg: null,
        open: false,
        duration: 2000
    });

    /**
     * 消息提示
     */
    const toastMsg = (type, msg, open = true, duration = 2000) => {
        setToast({type: type, msg: msg, open: open, duration: duration});
    }

    /**
     * 关闭消息
     */
    const closeToast = () => {
        setToast({type: toast.type, open: false});
    }

    useEffect(() => {
        //获取code
        let code = getVariable(window.location.href, "code");
        if (code !== null && code !== '') {
            //调用基础登录接口
            loginByWeChat({
                code: code,
                loginType: "WECHAT"
            }).then(result => {
                let success = checkKhResponse(result);
                if (success) {
                    //判断当前是否完成初始化
                    let isInitializeAuth = result["dataMap"]["isInitializeAuth"];
                    if (isInitializeAuth) {
                        //已初始授权
                        let sessionId = result["dataMap"]["sessionId"];
                        if (sessionId !== null && sessionId !== '') {
                            //存入sessionId
                            localStorage.setItem("sessionId", sessionId);
                            //跳转到user
                            navigate("/user");
                        } else {
                            setLoading(false);
                            toastMsg("error", "系统错误，请联系管理员")
                        }
                    } else {
                        let corpId = result["dataMap"]["corpId"];
                        //如果未初始化授权则需要到手动授权页面跳转
                        navigate("/privateOAuth",  {state: {corpId: corpId}});
                    }
                } else {
                    setLoading(false);
                    toastMsg("error", getKhErrorMsg(result))
                }
            })
        } else {
            setLoading(false);
            toastMsg("error", "系统错误，请联系管理员")
        }
    }, []);

    return (
        <Box sx={{
            display: 'flex',
            width: '100vw',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        }}>
            <Toast open={toast.open} duration={toast.duration} type={toast.type} msg={toast.msg} close={closeToast}/>
            {loading ? <CircularProgress/> : ""}
        </Box>
    )
}

export default LoginByWeChat;