import * as React from "react";
import {useState} from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {zhCN} from "date-fns/locale";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import CardActions from "@mui/material/CardActions";
import LoadingButton from "@mui/lab/LoadingButton";
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import SendIcon from '@mui/icons-material/Send';
import {getReport, getFinancialInfo, uploadFile} from "../../request/api/data/data_api";
import {checkKhResponse} from "../../util/util";
import {Refresh} from "@mui/icons-material";
import moment from "moment";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Toast from "../../component/Toast";

let taxationType = {
    "VAT": "增值税",//增值税
    "EXT": "附加税",//附加税
    "PROFIT": "所得税",//所得税
    "PERSON": "个税",//个税
    "CULTURAL": "文化事业建设费",//文化建设税
    "STAMP": "印花税",//印花税 STAMP
    "FINAL": "汇算清缴",//汇算清缴 STAMP
    "ANNUAL": "工商年报",//工商年报 ANNUAL
    "OTHERS": "其他"//其他
}

/**
 * 滑动组件
 */
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * 财务报表
 */
function Financial(props) {
    const {contactId, wx} = props;
    //账期
    let [period, setPeriod] = useState(new Date());
    //提示
    let [toast, setToast] = React.useState({
        type: "success",
        msg: null,
        open: false,
        duration: 2000
    });
    //报税回执列表
    let [financialDialogOpen, setFinancialDialogOpen] = useState(false);

    //loading
    let [loading, setLoading] = useState(false);

    //profit查询结果
    let [report, setReport] = useState("");

    //列表按钮禁用
    let [btnDisabled, setBtnDisabled] = useState({
        //资产负债表禁用
        finance: false,
        //利润额禁用
        profit: false,
        //现金表
        cash: false
    });

    let [financialFileId, setFinancialFileId] = useState("");

    //列表按钮加载
    let [btnLoading, setBtnLoading] = useState({
        finance: false,
        //利润额禁用
        profit: false,
        //现金表
        cash: false
    })

    /**
     * 消息提示
     */
    const toastMsg = (type, msg, open = true, duration = 2000) => {
        setToast({type: type, msg: msg, open: open, duration: duration});
    }

    /**
     * 关闭消息
     */
    const closeToast = () => {
        setToast({type: toast.type, open: false});
    }

    //监听财务回执关闭
    const handleFinancialClose = () => {
        setFinancialDialogOpen(false);
    };

    //财务报表
    let [financialFileList, setFinancialFileList] = useState([]);

    /**
     * 发送消息/文件到客户
     * type: 类型
     * id： type为file的时候id为mediaId， type为text的时候id为文本
     */
    const sendChatMsg = async (type, id) => {
        let p = new Promise(function (resolve, reject) {
            id = id.toString();
            switch (type) {
                case "file":
                    wx.invoke('sendChatMessage', {
                        msgtype: "file", //消息类型，必填
                        enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
                        file: {
                            mediaid: id, //文件的素材id
                        },
                    }, function (res) {
                        resolve(res.err_msg === 'sendChatMessage:ok');
                    })
                    break;
                case "text":
                    wx.invoke('sendChatMessage', {
                        msgtype: "text", //消息类型，必填
                        enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
                        text: {
                            content: id, //文件的素材id
                        },
                    }, function (res) {
                        resolve(res.err_msg === 'sendChatMessage:ok');
                    })
                    break;
                default:
                    resolve(false);
            }
        });
        return p;
    }

    const selectFileId = (fileId) => {
        setFinancialFileId(fileId);
    }

    const confirmSend = (fileId) => {
        uploadFile({khMediaId: fileId, externalContactId: contactId}).then(res => {
            let success = checkKhResponse(res);
            if (success) {
                let mediaId = res["dataMap"]["mediaId"];
                if (mediaId === null || mediaId === "") {
                    toastMsg("error", "发送失败");
                } else {
                    sendChatMsg("file", mediaId).then(res => {
                        if (res === true) {
                            toastMsg("success", "发送成功");
                            setFinancialDialogOpen(false);
                        } else {
                            toastMsg("error", "发送失败，请联系管理员");
                        }
                    })
                }
            } else {
                toastMsg("error", res["dataMap"]["msg"] === null ? "系统错误" : res["dataMap"]["msg"]);
            }
        });
    }

    //查询财务报表
    const queryReport = (type) => {
        switch (type) {
            case "balance":
                setBtnDisabled({
                    //资产负债表禁用
                    finance: false,
                    //利润额禁用
                    profit: true,
                    //现金表
                    cash: true
                });
                setBtnLoading({
                    //资产负债表禁用
                    finance: true,
                    //利润额禁用
                    profit: false,
                    //现金表
                    cash: false
                })
                break;
            case "profit":
                setBtnDisabled({
                    //资产负债表禁用
                    finance: true,
                    //利润额禁用
                    profit: false,
                    //现金表
                    cash: true
                });
                setBtnLoading({
                    //资产负债表禁用
                    finance: false,
                    //利润额禁用
                    profit: true,
                    //现金表
                    cash: false
                })
                break;
            case "cash":
                setBtnDisabled({
                    //资产负债表禁用
                    finance: true,
                    //利润额禁用
                    profit: true,
                    //现金表
                    cash: false
                });
                setBtnLoading({
                    //资产负债表禁用
                    finance: false,
                    //利润额禁用
                    profit: false,
                    //现金表
                    cash: true
                });
                break;
            default:
                toastMsg("error", "参数错误");
                break;
        }


        //查询后端
        //params.getExternalContactId(), userInfo.getCorpId(), params.getFinancialType(), params.getPeriod())
        getReport({
            externalContactId: contactId,
            financialType: type,
            period: moment(period).format("YYYYMM")
        }).then(res => {
            let success = checkKhResponse(res);
            if (success) {
                let reportId = res["dataMap"]["mediaId"];
                if (reportId === null || reportId === "") {
                    toastMsg("warning", "所选报表为空")
                } else {
                    setReport(reportId);
                    switch (type) {
                        case "balance":
                            setBtnLoading({
                                //资产负债表禁用
                                finance: false,
                                //利润额禁用
                                profit: false,
                                //现金表
                                cash: false
                            })
                            break;
                        case "profit":
                            setBtnLoading({
                                //资产负债表禁用
                                finance: false,
                                //利润额禁用
                                profit: false,
                                //现金表
                                cash: false
                            })
                            break;
                        case "cash":
                            setBtnLoading({
                                //资产负债表禁用
                                finance: false,
                                //利润额禁用
                                profit: false,
                                //现金表
                                cash: false
                            });
                            break;
                        default:
                            toastMsg("error", "参数错误");
                            break;
                    }
                }
            } else {
                toastMsg("error", res["dataMap"]["msg"] === null ? "系统错误" : res["dataMap"]["msg"]);
            }
        });
    }

    //
    const queryFinancialInfo = () => {
        setFinancialDialogOpen(true);
        //获取数据
        getFinancialInfo({externalContactId: contactId, period: moment(period).format("YYYYMM")}).then(res => {

            let success = checkKhResponse(res["dataMap"]);
            if (success) {
                let data = res["dataMap"]["dataMap"]["queryDeclaration"]["declarationDetailWrapperList"];
                if (data != null && data.length > 0) {
                    setFinancialFileList(data);
                }
            } else {
                toastMsg("error", "系统错误，请联系管理员");
            }
        })
    }

    //发送报表
    const sendReport = () => {
        if (report === null || report === "") {
            toastMsg("warning", "请选择报表");
        } else {
            sendChatMsg("file", report).then(res => {
                if (res === true) {
                    toastMsg("success", "发送成功");
                } else {
                    toastMsg("error", "发送失败，请联系管理员");
                }
            })
        }
    }

    //重选报表
    const reSelect = () => {
        setBtnLoading({
            finance: false,
            //利润额禁用
            profit: false,
            //现金表
            cash: false
        });
        setBtnDisabled({
            //资产负债表禁用
            finance: false,
            //利润额禁用
            profit: false,
            //现金表
            cash: false
        });
        setReport("");
    }

    return (
        <Box sx={{width: "100%"}}>

            {/* toast 默认 */}
            <Toast open={toast.open} duration={toast.duration} type={toast.type} msg={toast.msg} close={closeToast}/>

            <Card sx={{m: 2}}>
                <CardContent>

                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={zhCN}>
                        <DatePicker
                            cancelText={"关闭"}
                            okText={"确认"}
                            inputFormat={"yyyy 年 MM 月"}
                            views={['year', 'month']}
                            label="选择账期"
                            minDate={new Date('2012-03')}
                            maxDate={new Date()}
                            value={period}
                            onChange={(newValue) => {
                                setPeriod(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} helperText={null}/>}
                        />
                    </LocalizationProvider>

                    <List
                        sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                资产负债表、利润表、现金流量表
                            </ListSubheader>
                        }
                    >
                        <ListItemButton onClick={() => queryReport("balance")} disabled={btnDisabled.finance}>
                            <ListItemText primary="查询资产负债表"/>
                            {btnLoading.finance ? <CircularProgress size={20}/> : ""}
                        </ListItemButton>
                        <ListItemButton onClick={() => queryReport("profit")} disabled={btnDisabled.profit}>
                            <ListItemText primary="查询利润表"/>
                            {btnLoading.profit ? <CircularProgress size={20}/> : ""}
                        </ListItemButton>
                        <ListItemButton onClick={() => queryReport("cash")} disabled={btnDisabled.cash}>
                            <ListItemText primary="查询现金流量表"/>
                            {btnLoading.cash ? <CircularProgress size={20}/> : ""}
                        </ListItemButton>
                        <ListItemButton onClick={queryFinancialInfo}>
                            <ListItemText primary="查询税务报表"/>
                        </ListItemButton>
                    </List>
                </CardContent>

                <CardActions>
                    <LoadingButton
                        onClick={sendReport}
                        endIcon={<SendIcon/>}
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                    >
                        发送
                    </LoadingButton>

                    <LoadingButton
                        onClick={reSelect}
                        endIcon={<Refresh/>}
                        loadingPosition="end"
                        variant="contained"
                    >
                        重选
                    </LoadingButton>

                    <Box sx={{width: "10px"}}/>
                </CardActions>
            </Card>

            <Dialog
                open={financialDialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleFinancialClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"税务报表回执文件"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">

                        <List
                            sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                        >
                            {financialFileList.map((row, index) => (
                                <ListItemButton onClick={() => confirmSend(row.fileId)}>
                                    {taxationType[row.taxation]} : &nbsp;
                                    <ListItemText primary={row.fileName}/>
                                </ListItemButton>
                            ))}
                        </List>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleFinancialClose}>取消</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default Financial;
