import http from '../../http';

/**
 * 登录
 */
function login(params) {
    return http("/auth/login", params);
}

/**
 * 初始化登录
 */
function loginByHandOAuth(params) {
    return http("/auth/loginByHandOAuth", params);
}

/**
 * 初始化权限
 */
function initializationPermission(params) {
    return http("/auth/initializationPermission", params)
}

/**
 * 获取签名校验信息
 */
function getSignature(params) {
    return http("/auth/querySignatureInfo", params);
}

/**
 * 获取当前客户备注手机号
 */
function getCustomerMobileList(params) {
    return http("/auth/queryCustomerMobileList", params);
}

/**
 * 通过手机号获取快合联系人对应的客户
 */
function getCustomerListByMobile(params) {
    return http("/auth/queryCustomerListByMobile", params);
}

/**
 * 存入已选择的customerId
 */
function saveSelectCustomer(params) {
    return http("/auth/saveSelectCustomer", params);
}

/**
 * 检查是否有客户缓存
 */
function checkCustomerCache(params) {
    return http("/auth/checkHaveCustomerCache", params);
}

/**
 * 通过contactId查询客户信息
 */
function getCustomerInfo(params) {
    return http("/auth/queryCustomerInfo", params);
}

/**
 * 通过contactId解绑客户信息
 */
function unboundSelectedCustomer(params) {
    return http("/auth/unboundSelectedCustomer", params);
}

/**
 * 获取agentId
 */
function getAgentId(params) {
    return http("/auth/queryAgentId", params);
}

/**
 * 获取群客户列表
 */
function getGroupExternalContactList(params) {
    return http("/auth/queryGroupExternalContactList", params);
}

/**
 * 账号密码登录
 */
function loginByUsername(params) {
    return http("/auth/loginByUsername", params);
}

/**
 * 微信登录
 */
function loginByWeChat(params) {
    return http("/auth/loginByWeChat", params);
}

export {
    login,
    getSignature,
    getCustomerMobileList,
    getCustomerListByMobile,
    saveSelectCustomer,
    checkCustomerCache,
    getCustomerInfo,
    unboundSelectedCustomer,
    getAgentId,
    loginByHandOAuth,
    getGroupExternalContactList,
    loginByUsername,
    loginByWeChat,
    initializationPermission
}