import http from '../../http';

/**
 * 查询利润额
 */
function getProfit(params) {
    return http("/accdata/queryProfit", params);
}

/**
 * 查询工资表
 */
function getSalary(params) {
    return http("/accdata/querySalary", params);
}

/**
 * 查询财务报表
 */
function getReport(params) {
    return http("/accdata/queryFinancial", params);
}

/**
 * 获取文件列表
 */
function getFileList(params) {
    return http("/accdata/queryFileList", params);
}

/**
 * 选择文件上传
 */
function uploadFile(params) {
    return http("/accdata/selectFileUpload", params);
}

/**
 * 获取开票抬头信息
 */
function getCustomerTitle(params) {
    return http("/accdata/queryCustomerTitle", params);
}

/**
 * 保存发票抬头信息
 */
function saveCustomerTitle(params) {
    return http("/accdata/saveCustomerTitle", params);
}

/**
 * 获取小程序logo地址
 */
function getMiniProgramLogo() {
    return http("/accdata/queryMiniProgramLogo");
}

/**
 * 获取回执报表
 */
function getFinancialInfo(params) {
    return http("/accdata/queryFinancialInfo", params);
}

/**
 * 获取账套当前和结账日期
 */
function getBookPeriod(params) {
    return http("/accdata/queryBookPeriod", params);
}

export {
    getProfit,
    getSalary,
    getReport,
    getFileList,
    uploadFile,
    getCustomerTitle,
    saveCustomerTitle,
    getMiniProgramLogo,
    getFinancialInfo,
    getBookPeriod
}