import * as React from "react";
import {useEffect, useState} from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import {getCustomerTitle, saveCustomerTitle} from "../../request/api/data/data_api";
import {checkKhResponse} from "../../util/util";
import Toast from "../../component/Toast";

/**
 * 发票头信息
 */
function Invoice(props) {
    const {contactId, wx} = props;
    let [loading, setLoading] = useState(false);
    //企业名称
    let [companyName, setCompanyName] = useState("");
    //税号
    let [identificationCode, setIdentificationCode] = useState("");
    //地址
    let [address, setAddress] = useState("");
    //电话号
    let [phone, setPhone] = useState("");
    //银行名
    let [bankName, setBankName] = useState("");
    //银行账户
    let [bankAccount, setBankAccount] = useState("");

    let [toast, setToast] = React.useState({
        type: "success",
        msg: null,
        open: false,
        duration: 2000
    });

    /**
     * 消息提示
     */
    const toastMsg = (type, msg, open = true, duration = 2000) => {
        setToast({type: type, msg: msg, open: open, duration: duration});
    }

    /**
     * 关闭消息
     */
    const closeToast = () => {
        setToast({type: toast.type, open: false});
    }

    const saveTitle = () => {
        saveCustomerTitle({
            externalContactId: contactId.toString(),
            companyName: companyName.toString(),
            identificationCode: identificationCode.toString(),
            address: address.toString(),
            phone: phone.toString(),
            bankName: bankName.toString(),
            bankAccount: bankAccount.toString()
        }).then(res => {
            let success = checkKhResponse(res);
            if (success) {
                toastMsg("success", "保存成功");
            } else {
                toastMsg("error", res["dataMap"]["msg"] === null ? "系统错误" : res["dataMap"]["msg"]);
            }
        })
    }

    const queryCustomerTitle = () => {
        getCustomerTitle({externalContactId: contactId}).then(res => {
            let success = checkKhResponse(res);
            if (success) {
                let result = res["dataMap"];
                setCompanyName(result.businessName);
                setIdentificationCode(result.creditCode);
                setAddress(result.regLocation);
                setPhone(result.companyPhone);
                setBankName(result.bank);
                setBankAccount(result.bankAccount);
            } else {
                toastMsg("error", res["dataMap"]["msg"] === null ? "系统错误" : res["dataMap"]["msg"]);
            }
        })
    }

    useEffect(() => {
        queryCustomerTitle();
    }, [])

    return (
        <Box sx={{width: "100%"}}>

            {/* toast 默认 */}
            <Toast open={toast.open} duration={toast.duration} type={toast.type} msg={toast.msg} close={closeToast}/>

            <Card sx={{m: 2}}>
                <CardContent>
                    <Box
                        component={"form"}
                        sx={{
                            '& .MuiTextField-root': {width: "100%"},
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Box>
                            <TextField
                                onChange={(event => setCompanyName(event.target.value))}
                                required
                                label="企业名称"
                                value={companyName}
                            />
                        </Box>
                        <Box sx={{mt: 3}}>
                            <TextField
                                onChange={event => setIdentificationCode(event.target.value)}
                                value={identificationCode}
                                required
                                label="纳税人识别号"
                            />
                        </Box>
                        <Box sx={{mt: 3}}>
                            <TextField
                                onChange={event => setAddress(event.target.value)}
                                value={address}
                                label="企业地址"
                            />
                        </Box>
                        <Box sx={{mt: 3}}>
                            <TextField
                                onChange={event => setBankName(event.target.value)}
                                value={bankName}
                                label="开户行"
                            />
                        </Box>
                        <Box sx={{mt: 3}}>
                            <TextField
                                onChange={event => setPhone(event.target.value)}
                                value={phone}
                                type={"number"}
                                label="工商注册电话"
                            />
                        </Box>
                        <Box sx={{mt: 3}}>
                            <TextField
                                onChange={event => setBankAccount(event.target.value)}
                                value={bankAccount}
                                label="开户账号"
                            />
                        </Box>
                    </Box>
                </CardContent>
                <CardActions>
                    <LoadingButton
                        onClick={saveTitle}
                        endIcon={<SaveIcon/>}
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                    >
                        保存
                    </LoadingButton>
                </CardActions>
            </Card>
        </Box>
    )
}

export default Invoice;