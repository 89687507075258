import OAuth from '../pages/auth/OAuth';
import PrivateOAuth from "../pages/auth/PrivateOAuth";
import User from '../pages/auth/User';
import Test from "../pages/auth/Setting";
import Data from "../pages/data/Data";
import LoginByWeChat from "../pages/auth/LoginByWeChat";
import PrivateLogin from "../pages/auth/PrivateLogin";
import LoginByUsername from "../pages/auth/LoginByUsername";
import UsernameOAuth from "../pages/auth/UsernameOAuth";
import Error from "../pages/auth/Error";

/**
 * 存储其他页面，例如403,404等出错页面跳转
 */
const otherRouter = [

];

/**
 * 存储正常路由页面
 */
const pageRouter = [
    {
        path: '/',
        title: '登录',
        element: <UsernameOAuth />
    },
    {
        path: '/loginByUsername',
        title: '登录',
        element: <LoginByUsername />
    },
    {
        path: '/user',
        title: '用户',
        element: <User />
    },
    {
        path: '/setting',
        title: '设置',
        element: <Test />
    },
    {
        path: '/data',
        title: '数据',
        element: <Data />
    },
    {
        path: '/loginByWeChat',
        title: '登录',
        element: <LoginByWeChat />
    },
    {
        path: '/privateLogin',
        title: '授权登录',
        element: <PrivateLogin />
    },
    {
        path: '/privateOAuth',
        title: '手动授权',
        element: <PrivateOAuth />
    },
    {
        path: '/oauth',
        title: '授权',
        element: <OAuth />
    },
    {
        path: '/error',
        title: '错误',
        element: <Error />
    }
];
export { otherRouter, pageRouter };