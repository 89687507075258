import React, {useEffect} from "react";
import {useNavigate} from 'react-router-dom';
import Toast from "../../component/Toast";
import {Box, CircularProgress} from "@mui/material";
import {checkKhResponse, getKhErrorMsg, getVariable} from "../../util/util";
import {initializationPermission} from "../../request/api/auth/auth_api";
const localStorage = window.localStorage;

function PrivateLogin() {
    //navigate
    let navigate = useNavigate();
    //loading
    let [loading, setLoading] = React.useState(true);
    //toast
    let [toast, setToast] = React.useState({
        type: "success",
        msg: null,
        open: false,
        duration: 2000
    });

    /**
     * 消息提示
     */
    const toastMsg = (type, msg, open = true, duration = 2000) => {
        setToast({type: type, msg: msg, open: open, duration: duration});
    }

    /**
     * 关闭消息
     */
    const closeToast = () => {
        setToast({type: toast.type, open: false});
    }

    useEffect(() => {
        //获取code
        let code = getVariable(window.location.href, "code");
        let corpId = getVariable(window.location.href, "state").split("#")[0];
        if (code !== null && code !== '' && corpId !== null && corpId !== '') {
            //先去初始化权限
            initializationPermission({manualCode: code, corpId: corpId}).then(result => {
                let success = checkKhResponse(result);
                if (success) {
                    //初始化成功后，重新登录
                    navigate("/login");
                } else {
                    setLoading(false);
                    toastMsg("error", getKhErrorMsg(result));
                }
            });
        } else {
            setLoading(false);
            toastMsg("error", "系统错误，请联系管理员");
        }
    }, [])

    return (
        <Box sx={{
            display: 'flex',
            width: '100vw',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        }}>
            <Toast open={toast.open} duration={toast.duration} type={toast.type} msg={toast.msg} close={closeToast}/>
            {loading ? <CircularProgress/> : ""}
        </Box>
    )

}

export default PrivateLogin;